const submenuTitles = document.querySelectorAll('.submenu-title');
const navbar = document.querySelector('nav.navbar');
const navbarToggler = document.querySelector('.navbar-toggler');
const navbarCollapse = document.querySelector('.navbar-collapse');
const bsNavbarCollapse = new bootstrap.Collapse('.navbar-collapse', {
    toggle: false
});

let openSubmenu = null;

submenuTitles.forEach(title => title.addEventListener('click', onSubmenuTitleClick));

document.addEventListener('click', onDocumentClick);
window.addEventListener('resize', onWindowResize);

navbarCollapse.addEventListener('hide.bs.collapse', () => navbar.classList.remove('navbar-ti--light'));
navbarCollapse.addEventListener('show.bs.collapse', () => navbar.classList.add('navbar-ti--light'));

function onSubmenuTitleClick(event) {
    const clickedSubmenuTitle = event.target;
    const clickedSubmenu = event.currentTarget.nextElementSibling;

    if (openSubmenu) {
        openSubmenu.previousElementSibling.classList.toggle('submenu-title--active');
        openSubmenu.classList.toggle('d-none', true);
    }

    if (!openSubmenu || clickedSubmenu !== openSubmenu) {
        clickedSubmenuTitle.classList.toggle('submenu-title--active');
        clickedSubmenu.classList.toggle('d-none', false);
        openSubmenu = clickedSubmenu;
        navbar.classList.toggle('navbar-ti--light', true);
        bsNavbarCollapse.show();
    } else {
        openSubmenu = null;

        if (window.getComputedStyle(navbarToggler).getPropertyValue('display') === 'none') {
            navbar.classList.toggle('navbar-ti--light', false);
        }
    }
}

function closeSubmenu() {
    if (openSubmenu) {
        openSubmenu.classList.toggle('d-none', true);
        openSubmenu = null;
        navbar.classList.toggle('navbar-ti--light', false);
        submenuTitles.forEach(title => title.classList.toggle('submenu-title--active', false));
        bsNavbarCollapse.hide();
    }
}

function onDocumentClick(event) {
    if (!navbar.contains(event.target)) {
        closeSubmenu();
    }
}

function onWindowResize() {
    if (window.getComputedStyle(navbarToggler).getPropertyValue('display') === 'none') {
        bsNavbarCollapse.hide();
        closeSubmenu();
    }
}