new Swiper('#swiper-customer', {
    slidesPerView: 3,
    loop: false,
    autoplay: false,
    breakpoints: {
        0: {
            spaceBetween: 20,
            grid: {
                rows: 2,
            },
        },
        992: {
            spaceBetween: 60,
            grid: {
                rows: 2,
            },
        },
    }
});

