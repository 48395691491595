import { locales } from './languageHelper.js';

document.querySelectorAll('#language-selector-menu a').forEach(option => {
    option.addEventListener('click', (e) => {
        e.stopPropagation();
        e.preventDefault();

        let url = new URL(window.location);
        url.pathname = url.pathname.replace(buildLocaleRegex(), '');
        url.pathname = (e.target.id === 'default' ? '' : e.target.id) + url.pathname;
        window.location = url.toString();
    });
});

function buildLocaleRegex() {
    let regex = [];

    for (let locale of locales) {
        regex.push(`\\/${locale}`);
    }

    return new RegExp(regex.join('|'));
}